import * as yup from 'yup'
import { InputValidationEnum } from 'enums'

export const ruCulture = 'ru-RU'
export const enCulture = 'en-US'
export const kgCulture = 'ky-KG'
export const tjCulture = 'tg-Cyrl-TJ'
export const swCulture = 'sw-TZ'

export function getCountryLangauges(countryId) {
  switch (countryId) {
    case 1:
      return [allLanguages[0], allLanguages[1], allLanguages[2]]

    case 2:
      return [allLanguages[0], allLanguages[1], allLanguages[3]]

    case 3:
      return [allLanguages[1], allLanguages[4]]
  }
}

export function getLanguagesValidationSchema(countryId, intl, propertyName) {
  const shape = {}
  getCountryLangauges(countryId).forEach(el => {
    shape[propertyName + el.shortName] = yup
      .string()
      .typeError(intl.formatMessage({ id: 'validation.is_required' }))
      .required(intl.formatMessage({ id: 'validation.is_required' }))
      .matches(InputValidationEnum.NO_ONLY_SPACES, intl.formatMessage({ id: 'validation.not_valid' }))
  })

  return shape
}

export function getTranslationsList(data, initalData, propertyName) {
  const translations = []

  allLanguages.forEach(x => {
    let translation = undefined
    const prop = propertyName + x.shortName

    if (prop in data) translation = data[propertyName + x.shortName]
    else if (initalData !== undefined && prop in initalData) translation = initalData[prop]

    if (translation !== undefined)
      translations.push({
        [propertyName]: translation,
        cultureName: x.culture,
      })
  })

  return translations
}

export function getLanguagesInitValues(translations, cellData, propertyName) {
  const languages = {
    kyrgyz: {},
    english: {},
    russian: {},
    tajik: {},
    kiswahili: {},
  }

  translations.forEach(el => {
    if (el.cultureName === 'ru-RU') {
      languages.russian = el
    } else if (el.cultureName === 'ky-KG') {
      languages.kyrgyz = el
    } else if (el.cultureName === 'en-US') {
      languages.english = el
    } else if (el.cultureName === 'tg-Cyrl-TJ') {
      languages.tajik = el
    } else if (el.cultureName === 'sw-TZ') {
      languages.kiswahili = el
    }
  })

  return {
    [`${propertyName}Ru`]: languages?.russian?.[propertyName] || cellData.name,
    [`${propertyName}En`]: languages?.english?.[propertyName] || cellData.name,
    [`${propertyName}Kg`]: languages?.kyrgyz?.[propertyName] || cellData.name,
    [`${propertyName}Tj`]: languages?.tajik?.[propertyName] || cellData.name,
    [`${propertyName}Sw`]: languages?.kiswahili?.[propertyName] || cellData.name,
  }
}

export const allLanguages = [
  {
    fullName: 'russian',
    shortName: 'Ru',
    nativeName: 'Русский',
    culture: ruCulture,
  },
  {
    fullName: 'english',
    shortName: 'En',
    nativeName: 'English',
    culture: enCulture,
  },
  {
    fullName: 'kyrgyz',
    shortName: 'Kg',
    nativeName: 'Кыргыз тили',
    culture: kgCulture,
  },
  {
    fullName: 'tajik',
    shortName: 'Tj',
    nativeName: 'Тоҷикӣ',
    culture: tjCulture,
  },
  {
    fullName: 'kiswahili',
    shortName: 'Sw',
    nativeName: 'Kiswahili',
    culture: swCulture,
  },
]
